import React from 'react';
import { Link } from 'gatsby'
import { StaticQuery, graphql } from 'gatsby'

// Import font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import your fontawesome library. Do we need to import this?
import Fontawesome from '../components/Fontawesome';

import './Share.scss';

const ContactButtons = () => (
	<StaticQuery
		query={linksQuery}
		render={ (data) => {
		return (
			<div className="post-social" style={{ display: `flex` }} >
				{/*
				<a href={data.site.siteMetadata.myFaceBook} className="button facebook">
					<span className="icon">
						<FontAwesomeIcon icon={['fab', 'facebook-f']} />
					</span>
					<span className="text">Facebook</span>
				</a>
				*/}
				<a href={data.site.siteMetadata.myTwitter} className="button twitter">
					<span className="icon">
						<FontAwesomeIcon icon={['fab', 'twitter']} />
					</span>
					<span className="text">Twitter</span>
				</a>
				<a href={data.site.siteMetadata.myLinkedIn} className="button linkedin">
					<span className="icon">
						<FontAwesomeIcon icon={['fab', 'linkedin-in']} />
					</span>
					<span className="text">LinkedIn</span>
				</a>
			</div>
			)
		}}
	/>
);

// Create a query to get site data, so that it's not hard coded.
const linksQuery = graphql`
  query {
    site {
    	siteMetadata {
        	myLinkedIn
			myTwitter
			myFaceBook
        }
	}
  }
`

export default ContactButtons;