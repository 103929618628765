// Styled formatting
import styled from 'styled-components';

// Flex box style contents... Button pushes this already to it's width.
export const FormOwn = styled.div`
  width: 60%;
`;

export const Button = styled.button`
  text-decoration: none;
  width: 100%;
  height: 40px;
  color: #fff;
  background-color: transparent;
  border-radius: 25px;
  border: 1px solid;
  padding: 6px;
  border-color: hotpink;
  text-decoration: none;

  :hover {
    background-color: hotpink;
    border-color: hotpink;
    color: fff;
  }
`;

export const Title = styled.h1`
  font-family: 'Orbitron', sans-serif;
  font-weight: 600;
  color: #4d4d4d;
  font-size: 2.2em;
`;

export const Text = styled.div`
  font-family: 'raleway', sans-serif;
  font-size: 0.8em;
  color: ${props => props.color || '#4d4d4d'}
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  color: #777;
  font-family: "raleway", sans-serif;
  font-size: 0.8em;
  margin: 0.5em 0;
  position: relative;
`;