import React from 'react'

// Styling with styled, first styled, then the definitions
// import styled from 'styled-components'
import { FormOwn, Button, Title, Text, Label } from './formStyles.js'

// Axios
import axios from 'axios'

// Formik forms
import { Formik, Form, Field, ErrorMessage } from 'formik'

// Sweet alert 2
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

class MyForm extends React.Component {

    render() {

        // Create an instance of Swal.
        const MySwal = withReactContent(Swal)

        const initialValues={ email: '',
            contactee: '',
            message: ''
        }

        // How to reset form, tip.
        // https://github.com/jaredpalmer/formik/issues/446
        return(
            <Formik
                initialValues = {initialValues}
                validate={values => {

                    let errors = {}

                    if (!values.email) {
                        errors.email = 'Required'
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email))
                    {
                        errors.email = 'Invalid email address'
                    }
                    if (!values.contactee) {
                        errors.contactee = 'Required'
                    }
                    if (!values.message) {
                        errors.message = 'Required'
                    }
                    return errors
                }}

                onSubmit={(values, {
                    setSubmitting, resetForm
                    }) => {
                        console.log(values)
                        console.log("e-mail: " + values.email)
                        console.log("contactee: " + values.contactee)
                        console.log("message: " + values.message)
                        console.log("-------")

                        // using atm https://formcarry.com/
                        // https://formcarry.com/documentation/axios-example
                        axios.post('https://formcarry.com/s/1E7qZNtriIm', {email: values.email, contactee: values.contactee, message: values.message}, {headers: {'Accept': 'application/json'}})
                        .then(function (response) {
                            console.log(response);
                            MySwal.fire({
                                title: 'Message sent.',
                                text: 'Your message was sent successfully.',
                                type: 'success',
                                confirmButtonText: 'Cool'
                            }).then(() => {
                                return MySwal.fire({
                                    title: 'Thank you.',
                                    text: 'I will try to get back to you ASAP.',
                                    confirmButtonText: 'Close'
                                })
                            })
                        })
                        .catch(function (error) {
                            console.log(error);
                            MySwal.fire({
                                title: 'Send error.',
                                text: 'Your message was not sent.',
                                type: 'error',
                                confirmButtonText: 'Ok'
                            })
                        });

                        resetForm(initialValues)
                        setSubmitting(false)
                    }}
            >
                {({ isSubmitting, errors }) => (
                    <div>
                        <Title>Contact form</Title>
                        You can contact me via this form.<br/>
                        Only professional / business inquiries, thank you!
                        <FormOwn>
                            <Form>

                                <Label>E-mail *</Label>
                                <Text>
                                <Field type="email" name="email" placeholder="your e-mail" style={errors.message ? { width: '100%', height: '25px', border: '1px solid red'} : { width: '100%', height: '25px' } }/>
                                <ErrorMessage name="email" component="div" style={{color: 'red'}} />
                                </Text>

                                <Label>Name *</Label>
                                <Text>
                                <Field type="text" name="contactee" placeholder="your name" style={errors.message ? { width: '100%', height: '25px', border: '1px solid red'} : { width: '100%', height: '25px' } } / >
                                <ErrorMessage name="contactee" component="div" style={{color: 'red'}} />
                                </Text>

                                <Label>Message *</Label>
                                <Text>
                                <Field type="text" name="message" component="textarea" placeholder="Your message" style={errors.message ? { resize: 'none', width: '100%', height: '150px', border: '1px solid red'} : { resize: 'none', width: '100%', height: '150px' } }/>
                                <ErrorMessage name="message" component="div" style={{color: 'red'}} />
                                </Text>

                                <div style={{ paddingTop: '10px', paddingBottom: '10px' }} >
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        Submit
                                    </Button>
                                </div>

                            </Form>
                        </FormOwn>

                    </div>
                )}
            </Formik>
        )
    }
}

export default MyForm